import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import moment from 'moment';
import { Select2ScrollEvent, Select2SearchEvent } from 'ng-select2-component';
import { AnimationOptions } from 'ngx-lottie';
import { SingleStopReservationType, UserStatus, UserType } from 'src/app/models/enums';
import { FavouritesService } from 'src/app/services/favourites/favourites.service';
import { images } from 'src/images';
import { newBookingForm, UserBookingData } from '../../../forms/bookingForms';
import { IAvailability, IBookingCreate, IBookingSeat, IFavourites, IFindResult, IReservationAvailability, IReservationAvailabilityFailure, IServiceInfo, IUserRegular } from '../../../models';
import { BookingsService, LiteralService, TownsService, UsersService, UtilsService } from '../../../services';
import { IBookingDetails } from 'src/app/models/bookings/bookingDetails';

@Component({
  selector: 'app-booking-modal',
  templateUrl: './booking-modal.component.html',
  styleUrl: './booking-modal.component.scss',
})
export class BookingModalComponent implements OnInit, OnChanges {

  @Input() newBooking: IBookingCreate = {} as IBookingCreate;
  @Input() creatingBooking: boolean = false;
  @Input() bookingFromFavourite = false;
  @Input() hasAlterations = false;
  @Output() getServiceInfosEventEmitter = new EventEmitter<any>();
  @Output() selectODEventEmitter = new EventEmitter<any>();
  @Output() goToList = new EventEmitter<any>();
  @Output() showMaxReservationTimeModalEventEmitter = new EventEmitter<any>();
  @Output() showExceedingKmLimitModalEventEmitter = new EventEmitter<any>();
  @Output() showBlockedUserModalEventEmitter = new EventEmitter<any>();
  @Output() selectStop = new EventEmitter<any>();
  @Output() openFavourite = new EventEmitter<number>();
  @Output() changeTown = new EventEmitter<any>();
  @Output() showAlterationModal = new EventEmitter();
  public bookingForm: any;
  public availabilities: IFindResult = { success: [], failure: [] };

  public serviceInfos: IServiceInfo[] = [];

  public currentStep: number = 1;
  public numberSteps: number = 3;

  public steps = [ 1 ];

  private destinationsStops: unknown[];

  public availabilitiesDates: any[] = [];
  public availabilitiesSeparateByDate: any = {};
  public loadingAvailabilities = false;
  public currentPage = 0;

  public pax = 0;
  public prm = 0;

  public isManualBooking = false;
  public currentElement = 1;
  public totalElements = 0;

  public images = images;
  public Object = Object;
  public moment = moment;

  public isBack = false;

  public favourite: IFavourites;
  public dataLoaded = false;
  public favouriteByParams = false;

  public stopOriginNotification = false;
  public stopDestinationNotification = false;

  public isSingleReservation: boolean;

  public possibleSwap = false;

  public rebookBooking: IBookingDetails;

  public optionsPin: AnimationOptions = {
    path: '/assets/animations/pin.json'
  };

  public optionsBus: AnimationOptions = {
    path: '/assets/animations/bus.json'
  };

  constructor(private usersService: UsersService,
              private townsService: TownsService,
              private bookingsService: BookingsService,
              private favouritesService: FavouritesService,
              public literalService: LiteralService,
              public utilsService: UtilsService,
              private activatedRoute: ActivatedRoute) {}

  async ngOnInit() {
    if (this.bookingFromFavourite && this.activatedRoute.snapshot.queryParamMap.get('userId')) {
      this.activatedRoute.queryParamMap.subscribe(async (params) => {
        this.favouriteByParams = true;
        const favouriteId = params.get('favouriteId');
        const userId = params.get('userId');
        this.newBooking.user = await this.usersService.getUser(UserType.Regular, Number(userId)) as IUserRegular;
        const favourite: IFavourites = await this.favouritesService.getFavourite(Number(favouriteId));
        this.favourite = favourite;
        this.newBooking.favourite = this.favourite;
        this.setData(this.newBooking.user, this.newBooking.favourite, undefined);
      });
    } else if (this.activatedRoute.snapshot.queryParamMap.get('bookingId') && !this.isBack) {
      this.activatedRoute.queryParamMap.subscribe(async (params) => {
        const bookingId = params.get('bookingId');
        this.rebookBooking = (await this.bookingsService.getBooking(Number(bookingId))) as IBookingDetails;
        this.newBooking.user = await this.usersService.getUser(UserType.Regular, Number(this.rebookBooking.userId)) as IUserRegular;
        this.setData(undefined, undefined, this.rebookBooking);
        this.dataLoaded = true;
      });
    } else {
      if (!this.newBooking.destinationStops) this.newBooking.destinationStops = [];
      if (moment(this.newBooking.date).isValid()) {
        this.newBooking.date = moment(this.newBooking.date).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
      } else {
        const dates = this.newBooking.date.split(',');
        this.newBooking.date = dates.toString();
      }
      this.newBooking.time = this.newBooking.time && moment(this.newBooking.time, 'HH:mm').format('HH:mm') || moment().format('HH:mm');
      console.log(this.serviceInfos)
      await this.updateBookingForm(new UserBookingData({showMoreUsers: false}))
      // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false}), this.serviceInfos, undefined, [''], this.isSingleReservation && (this.bookingFromFavourite !== undefined || this.rebookBooking !== undefined));
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!changes['newBooking'].currentValue.targetUserId && changes['newBooking'].previousValue && changes['newBooking'].previousValue.targetUserId) {
      this.bookingFromFavourite = false;
      this.back();
    } else if (changes['newBooking'] && changes['newBooking'].currentValue.targetUserId && this.bookingFromFavourite) {
      this.favouriteByParams = false;
      this.newBooking = {} as IBookingCreate;
      this.newBooking.user = await this.usersService.getUser(UserType.Regular, Number(changes['newBooking'].currentValue.targetUserId)) as IUserRegular;
      const favourite: IFavourites = await this.favouritesService.getFavourite(changes['newBooking'].currentValue.favourite.id);
      this.favourite = favourite;
      this.newBooking.favourite = this.favourite;
      this.setData(this.newBooking.user, this.newBooking.favourite, undefined, false);
    }
  }

  setData = async (user?: IUserRegular, favourite?: IFavourites, bookingDetail?: IBookingDetails, showBlockedUserModal: boolean = true) => {
    this.dataLoaded = false;
    this.newBooking.targetUserId = bookingDetail ? bookingDetail.userId! : user!.id!;
    if (this.newBooking.user && this.newBooking.user.status?.toUpperCase() === UserStatus.BLOCKED && showBlockedUserModal) {
      const penalty = { penaltyEndDate: this.newBooking.user.penaltyEndDate, penaltyReason: this.newBooking.user.penaltyReason };
      this.showBlockedUserModal(penalty);
    }
    await this.updateBookingForm(new UserBookingData({showMoreUsers: false}), ['user']);
    // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false}), undefined, undefined, ['user']);
    !this.steps.includes(2) && this.steps.push(2);
    this.currentStep = 2;
    
    this.newBooking.townId = bookingDetail ? bookingDetail.service.town.id! : this.newBooking.townId || favourite!.town!.id!;
    this.serviceInfos = await this.townsService.getServices(this.newBooking.townId);
    await this.updateBookingForm(undefined, !this.isBack && ['town'] || ['']);
    // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, this.serviceInfos, undefined, !this.isBack && ['town']);
    this.getServiceInfosEventEmitter.emit({serviceInfos: this.serviceInfos, townId: this.newBooking.townId});
    if (this.isSingleReservation) {
      this.destinationsStops = this.newBooking.stops;
    }

    this.newBooking.originStopId = bookingDetail ? bookingDetail.origin.serviceStopId! : this.newBooking.originStopId || favourite!.inStop!.id!;
    bookingDetail ? this.setLocation(bookingDetail.origin.serviceStopId!) : this.setLocation(favourite!.inStop!.id!);
    // this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
    if (!this.isSingleReservation) {
      this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
    }
    await this.updateBookingForm(undefined, ['stop']);
    // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, undefined, this.destinationsStops, ['stop']);
    this.selectODEventEmitter.emit({newBooking: this.newBooking, type: 'origin'});
    this.selectStop.emit({stopId: this.newBooking.originStopId, townId: this.newBooking.townId});

    if (!this.isBack) {
      this.newBooking.destinationStops = [];
      this.addSeat({outStopId: bookingDetail ? bookingDetail.destination.serviceStopId : favourite!.outStop?.id, pax: bookingDetail ? bookingDetail.seats : favourite!.passengers, prm: bookingDetail ? bookingDetail.prmSeats : favourite!.prmPassengers });    
      await this.updateBookingForm();
      // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, undefined, this.destinationsStops);
      this.selectODEventEmitter.emit({newBooking: this.newBooking, type: 'destination'});
    }

    this.getPassengers();
    this.newBooking.date = moment(this.newBooking.date).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
    this.newBooking.time = bookingDetail ? moment().format('HH:mm') : this.newBooking.time && moment(this.newBooking.time, 'HH:mm').format('HH:mm') || favourite!.time!;
    await this.updateBookingForm();
    // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, this.serviceInfos);
    if (this.favouriteByParams) {
      this.dataLoaded = true;
    }
  };

  // set = async (data: any, valueToChange: string, doExtraAction: boolean = true) => {
  //   if (data) {
  //     switch (valueToChange) {
  //       case 'targetUserId': {
  //           this.newBooking.targetUserId = data.id;
  //           this.newBooking.user = await this.usersService.getUser(UserType.Regular, data.id) as IUserRegular;
  //           if (doExtraAction) this.newBooking.townId = data.town.id;
  //           if (this.newBooking.user && this.newBooking.user.status?.toUpperCase() === UserStatus.BLOCKED) {
  //             const penalty = { penaltyEndDate: this.newBooking.user.penaltyEndDate, penaltyReason: this.newBooking.user.penaltyReason };
  //             this.showBlockedUserModal(penalty);
  //           }
  //           this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false}), undefined, undefined, ['user']);
  //           !this.steps.includes(2) && this.steps.push(2);
  //         }
  //         break;
  //       case 'town': {
  //           this.newBooking.townId = data;
  //           this.serviceInfos = await this.townsService.getServices(this.newBooking.townId);
  //           this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, this.serviceInfos, undefined, this.dataLoaded && ['town']);
  //           this.getServiceInfosEventEmitter.emit({serviceInfos: this.serviceInfos, townId: this.newBooking.townId});
  //           this.isBack = false;
  //           this.dataLoaded = true;
  //           this.changeTown.emit();
  //           this.possibleSwap = false;
  //           this.isSingleReservation = this.serviceInfos && this.serviceInfos?.some((serviceInfo: IServiceInfo) => serviceInfo.singleStopReservations);
  //           if (this.isSingleReservation) {
  //             this.destinationsStops = this.newBooking.stops;
  //           }
  //         }
  //         break;
  //       case 'originStopId': {
  //           this.newBooking.originStopId = data;
  //           let serviceInfoData: IServiceInfo = this.serviceInfos[0];
  //           this.serviceInfos.filter((serviceInfo: IServiceInfo) => {
  //             const stop = serviceInfo.stops.find((stop: any) => {
  //               return stop.id === data;
  //             });
  //             if (stop) serviceInfoData = serviceInfo;
  //           });
  //           this.newBooking.serviceInfo = serviceInfoData;
  //           this.setLocation(data);
  //           if (!this.isSingleReservation) {
  //             this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
  //           }
  //           this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, this.serviceInfos, this.destinationsStops, ['stop']);
  //           this.selectODEventEmitter.emit({newBooking: this.newBooking, type: 'origin'});
  //           this.selectStop.emit({destinationsStops: this.destinationsStops});
  //           if (this.isSingleReservation) {
  //             this.setDestinationStops(undefined);
  //           }
  //         }
  //         break;
  //       case 'destinationsStops':
  //         this.setDestinationStops(data);
  //         break;
  //       case 'date':
  //         this.newBooking.date = '';
  //         const dates = data.split(',');
  //         dates.pop();
  //         dates.forEach((date: any) => {
  //           this.newBooking.date += moment(date.trim(), 'DD/MM/YYYY').format('YYYY-MM-DD') + ',';
  //         });
  //         this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, this.serviceInfos, undefined, ['']);
  //         break;
  //       case 'time':
  //         this.newBooking.time = data;
  //         this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, this.serviceInfos, undefined, ['']);
  //         break;  
  //       default:
  //         break;
  //     }
  //     this.currentStep = this.steps.at(-1)!;
  //   }
  // };

  public updateBookingForm = async (userBookingData?: UserBookingData, additionalFields = ['']) => {
    this.isSingleReservation = this.serviceInfos?.some((serviceInfo: IServiceInfo) => serviceInfo.singleStopReservations);
    const favouriteOrRebookSingle = this.isSingleReservation && (this.bookingFromFavourite || this.rebookBooking !== undefined);
    console.log(this.isSingleReservation, this.bookingFromFavourite, this.rebookBooking)
    console.log(this.isSingleReservation)
    console.log(this.bookingFromFavourite)
    console.log(this.rebookBooking !== undefined)
    console.log(this.bookingFromFavourite || this.rebookBooking !== undefined)
    console.log(this.isSingleReservation && (this.bookingFromFavourite || this.rebookBooking !== undefined))
    this.bookingForm = await newBookingForm(this.newBooking, this.usersService, userBookingData, this.serviceInfos, this.destinationsStops, additionalFields, favouriteOrRebookSingle);
  };

  set = async (data: any, valueToChange: string, doExtraAction: boolean = true) => {
    if (!data) return;
  
    switch (valueToChange) {
      case 'targetUserId': {
        this.newBooking.targetUserId = data.id;
        this.newBooking.user = await this.usersService.getUser(UserType.Regular, data.id) as IUserRegular;
        
        if (doExtraAction) this.newBooking.townId = data.town.id;
  
        if (this.newBooking.user?.status?.toUpperCase() === UserStatus.BLOCKED) {
          const { penaltyEndDate, penaltyReason } = this.newBooking.user;
          this.showBlockedUserModal({ penaltyEndDate, penaltyReason });
        }
  
        await this.updateBookingForm(undefined, ['user']);
        if (!this.steps.includes(2)) this.steps.push(2);
        break;
      }
      case 'town': {
        this.newBooking.townId = data;
        this.serviceInfos = await this.townsService.getServices(this.newBooking.townId);
        
        await this.updateBookingForm(undefined, ['town']);
        
        this.getServiceInfosEventEmitter.emit({ serviceInfos: this.serviceInfos, townId: this.newBooking.townId });
        this.changeTown.emit();
  
        this.isBack = false;
        this.dataLoaded = true;
        this.possibleSwap = false;
        this.changeTown.emit();
  
        this.isSingleReservation = this.serviceInfos?.some((serviceInfo: IServiceInfo) => serviceInfo.singleStopReservations);
  
        if (this.isSingleReservation) {
          this.destinationsStops = this.newBooking.stops;
        }
  
        break;
      }
      case 'originStopId': {
        this.newBooking.originStopId = data;
        
        const serviceInfoData = this.serviceInfos.find(serviceInfo =>
          serviceInfo.stops.some(stop => stop.id === data)
        ) || this.serviceInfos[0];
  
        this.newBooking.serviceInfo = serviceInfoData;
        this.setLocation(data);
  
        if (!this.isSingleReservation) {
          this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
        }
  
        await this.updateBookingForm(undefined, ['stop']);
        this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'origin' });
        this.selectStop.emit({ destinationsStops: this.destinationsStops });
  
        if (this.isSingleReservation) {
          this.setDestinationStops(undefined);
        }
  
        break;
      }
      case 'destinationsStops': {
        this.setDestinationStops(data);
        break;
      }
      case 'date': {
        this.newBooking.date = data.split(',').slice(0, -1).map((date: string) => 
          moment(date.trim(), 'DD/MM/YYYY').format('YYYY-MM-DD')
        ).join(',') + ',';
  
        await this.updateBookingForm();
        break;
      }
      case 'time': {
        this.newBooking.time = data;
        await this.updateBookingForm();
        break;
      }
      default:
        break;
    }
  
    this.currentStep = this.steps.at(-1)!;
  };

  addSeat = (data: any, fromFavourite: boolean = false) => {
    if (fromFavourite) {
      this.setDestinationStops(data);
    } else {
      let destination: any = 0;
      destination = this.destinationsStops.find((destination: any) => destination.id === data.outStopId);
      let bookingSeat: IBookingSeat;
      for (let index = 0; index < data.pax; index++) {
        bookingSeat = { exitStop: { id: destination.id, name: destination.name }, prm: false };
        this.newBooking.destinationStops.push(bookingSeat);
      }
      for (let index = 0; index < data.prm; index++) {
        bookingSeat = { exitStop: { id: destination.id, name: destination.name }, prm: true };
        this.newBooking.destinationStops.push(bookingSeat);
      }
      this.setLocation(this.newBooking.destinationStops[0].exitStop.id!, false);
    }
    this.possibleSwap = true;
  };

  setDestinationStops = async (data: any) => {
    let destination: any;
    destination = typeof data === 'object' ? { ...this.newBooking.destinationStops } : this.destinationsStops.find((d: any) => d.id === data);
    // let singleStopReservationType = SingleStopReservationType.ORIGIN;
    let singleStopReservationType = this.newBooking.originStopId ? SingleStopReservationType.ORIGIN : SingleStopReservationType.DESTINATION;
    let bookingSeat: IBookingSeat;
    if (singleStopReservationType === SingleStopReservationType.ORIGIN && !destination) {
      bookingSeat = {
        exitStop: { id: 0, name: '' },
        prm: data && data.type === 'prm' || this.newBooking.user.prm
      };
    } else {
      bookingSeat = {
        exitStop: { id: destination[0]?.exitStop?.id || destination.id, name: destination[0]?.exitStop?.name || destination.name },
        prm: data.type === 'prm' || this.newBooking.user.prm
      };
    }

    if (typeof data === 'object') {
      let index = -1;
      const existingSeat = this.newBooking.destinationStops.find((d: any, i: number) => { index = i; return d.prm === bookingSeat.prm; });

      if (existingSeat && data.key === 'minus') this.newBooking.destinationStops.splice(index, 1);
      else if (data.key === 'plus') this.newBooking.destinationStops.push(bookingSeat);
      else if (data.key === 'value' && data.value !== '') {
        this.newBooking.destinationStops = this.newBooking.destinationStops.filter((d: any) => d.prm !== bookingSeat.prm);
        Array.from({ length: data.value }).forEach(() => this.newBooking.destinationStops.push(bookingSeat));
      }
      destination && this.setLocation(destination[0].id, false);
    } else {
      this.newBooking.destinationStops = this.newBooking.destinationStops.length > 1
        ? this.newBooking.destinationStops.map((d: IBookingSeat) => ({ ...bookingSeat, prm: d.prm }))
        : [bookingSeat];
        destination && this.setLocation(destination.id, false);
    }

    this.newBooking.destinationStops = [...this.newBooking.destinationStops];
    this.pax = this.newBooking.destinationStops.filter((d: any) => !d.prm).length;
    this.prm = this.newBooking.destinationStops.filter((d: any) => d.prm).length;
    await this.updateBookingForm(undefined, ['destination']);
    singleStopReservationType === SingleStopReservationType.DESTINATION && this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'destination' });
    this.possibleSwap = true;
    console.log(!this.newBooking.originStopId || this.newBooking.destinationStops.length === 0, !this.newBooking.originStopId && this.newBooking.destinationStops.length === 0);
    console.log(this.newBooking.originStopId, this.newBooking.destinationStops);
  };

  setLocation = (id: number, origin = true) => {
    this.serviceInfos.map((serviceInfo: IServiceInfo) => {
      serviceInfo.stops.find((stop: any) => {
        if (stop.id === id) {
          origin ?
            this.serviceInfos.map((serviceInfo: IServiceInfo) => {
              serviceInfo.stops.find((stop: any) => {
                if (stop.id === id) {
                  this.newBooking.originStopLocation = {
                    lat: stop.location.geometry.coordinates[1],
                    lng: stop.location.geometry.coordinates[0]
                  };
                }
              });
            })
          :
            this.serviceInfos.map((serviceInfo: IServiceInfo) => {
              serviceInfo.stops.find((stop: any) => {
                if (stop.id === id) {
                  this.newBooking.destinationStopLocation = {
                    lat: stop.location.geometry.coordinates[1],
                    lng: stop.location.geometry.coordinates[0]
                  };
                }
              });
            });
        }
      });
    });
  };

  scroll = async (event: Select2ScrollEvent) => {
    if (event.way === 'down') {
      if (event.search !== '') {
        await this.updateBookingForm(new UserBookingData({showMoreUsers: true, search: true, value: event.search}));
        // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: true, search: true, value: event.search}));
      } else {
        await this.updateBookingForm(new UserBookingData({showMoreUsers: true}));
        // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: true}));
      }
    } 
  };

  search = async (event: Select2SearchEvent) => {
    const dropDown = document.getElementsByClassName('select2-results__options')[0];
    console.log(event)
    const value = event.search;
    if (value !== '') {
      await this.updateBookingForm(new UserBookingData({showMoreUsers: false, search: true, value: value}));
      // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false, search: true, value: value}));
      dropDown.scroll({
        top: 0
      });
    } else {
      await this.updateBookingForm(new UserBookingData({showMoreUsers: false}));
      // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false}));
    }
  };

  change = (data: any, valueToChange: string) => {
    if (valueToChange === 'destinationsStops') {
      this.set(data, 'destinationsStops');
    } else if (valueToChange === 'date') {
      this.set(data.target.value, 'date');
    } else if (valueToChange === 'time') {
      this.set(data.target.value, 'time');
    }
  };

  getAvailabilities = async () => {
    window.scrollTo(0, 0);
    this.loadingAvailabilities = true;
    !this.steps.includes(3) && this.steps.push(3);
    this.currentStep = this.steps.at(-1)!;
    this.availabilities =  { success: [], failure: [] };
    this.availabilitiesDates = [];
    this.availabilitiesSeparateByDate =  {};
    let paxOutStopId: number[] = [];
    let prmOutStopId: number[] = [];
    let singleStopReservationType = SingleStopReservationType.ORIGIN;
    singleStopReservationType = this.newBooking.originStopId ? SingleStopReservationType.ORIGIN : SingleStopReservationType.DESTINATION;
    if (!this.isSingleReservation || singleStopReservationType === SingleStopReservationType.DESTINATION) {
      this.newBooking.destinationStops && this.newBooking.destinationStops.filter((seats: IBookingSeat) => {
        if (!seats.prm) {
          paxOutStopId.push(seats.exitStop.id!);
        } else {
          prmOutStopId.push(seats.exitStop.id!);
        }
      });
    } else {
      paxOutStopId = this.utilsService.counter(this.newBooking.pax).map(() => 0);
      prmOutStopId = this.utilsService.counter(this.newBooking.prm).map(() => 0);
    }
    console.log(this.newBooking.date)
    const dates = this.newBooking.date.split(',').filter((date: string) => {
      return date !== '';
    });
    console.log(dates)
    const dateTimes = dates.map((date: any) => {
      console.log(this.newBooking.time)
      return moment(date + ' ' + this.newBooking.time).format('YYYY-MM-DD[T]HH:mm');
    });
    console.log(dateTimes)
    console.log("this.newBooking", this.newBooking)

    await this.townsService.getAvailabilities(
      this.newBooking.townId,
      this.newBooking.targetUserId,
      this.newBooking.originStopId,
      dateTimes,
      undefined,  // is_arrival_time: boolean = false
      paxOutStopId,
      undefined,  // reservationId?: number
      prmOutStopId,
      this.isSingleReservation
    ).then((resp: any) => {
      this.availabilities = resp;
    }, (error: any) => {
      console.log("ERROR", error);
      this.back();
    });
    

    this.availabilities.success.map((successAvailabilities: IReservationAvailability) => {
      const date = moment(successAvailabilities.dateTime).format('YYYY-MM-DD');
      if (!this.availabilitiesSeparateByDate[date]) this.availabilitiesSeparateByDate[date] = { success: [], failure: [] };
      this.availabilitiesSeparateByDate[date]['success'].push(successAvailabilities);
      if (!this.availabilitiesDates.includes(date)) this.availabilitiesDates.push(date);
    });
    this.availabilities.failure.map((failureAvailabilities: IReservationAvailabilityFailure) => {
      const date = moment(failureAvailabilities.dateTime).format('YYYY-MM-DD');
      if (!this.availabilitiesSeparateByDate[date]) this.availabilitiesSeparateByDate[date] = { success: [], failure: [] };
      this.availabilitiesSeparateByDate[date]['failure'].push(failureAvailabilities);
      if (!this.availabilitiesDates.includes(date)) this.availabilitiesDates.push(date);
    });
    this.totalElements = Object.keys(this.availabilitiesSeparateByDate).length;
    this.loadingAvailabilities = false;
  };

  getPassengers = () => {
    if (this.bookingFromFavourite) {
      this.pax = this.favourite.passengers!;
      this.prm = this.favourite.prmPassengers!;
    } else {
      this.pax = this.newBooking.destinationStops.filter((destination: any) => !destination.prm).length;
      this.prm = this.newBooking.destinationStops.filter((destination: any) => destination.prm).length;
    }
  };

  manualBooking = () => {
    this.isManualBooking = true;
  };

  createBooking = async (availability: IReservationAvailability) => {
    this.creatingBooking = true;
    this.newBooking.availabilityId = availability.availabilityId;
    this.newBooking.originStopId = availability.inStop.id!;
    this.newBooking.destinationStops.forEach((destination: any) => {
      destination.exitStop.id = availability.outStop.id!;
      destination.exitStop.name = availability.outStop.name!;
    });
    this.newBooking.destinationId = availability.outStop.id!;
    this.newBooking.serviceAvailabilityResponseId = availability.serviceAvailabilityResponseId;
    this.newBooking.serviceId = availability.serviceId;
    this.newBooking.tripId = availability.expeditionId;
    this.bookingsService.createBooking(this.newBooking).then(() => {
      if (this.currentElement === this.totalElements) {
        this.goToList.emit();
      } else {
        this.currentPage++;
        this.currentElement++;
      }
      this.creatingBooking = false;
    }, (error) => {
      console.log("ERROR", error);
      this.back();
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bookMultiple = async (availabilities: IReservationAvailability[]) => {
    const availabilitiesToBook = this.availabilitiesDates.map((date: any) => {
      return this.availabilitiesSeparateByDate[date].success[0];
    });
    const newBookings: IBookingCreate[] = [] as IBookingCreate[];
    availabilitiesToBook.map((availability: IReservationAvailability, index: number) => {
      newBookings[index] = this.newBooking;
      newBookings[index].availabilityId = availability.availabilityId;
      newBookings[index].serviceAvailabilityResponseId = availability.serviceAvailabilityResponseId;
      newBookings[index].serviceId = availability.serviceId;
      newBookings[index].tripId = availability.expeditionId;
    });
  };

  showMaxReservationTimeModal = () => {
    this.showMaxReservationTimeModalEventEmitter.emit();
    this.back();
  };
  
  showExceedingKmLimitModal = (availability: IAvailability) => { 
    this.newBooking.availabilityId = availability.availabilityId;
    this.newBooking.originStopId = availability.inStop.id!;
    this.newBooking.destinationStops.forEach((destination: any) => {
      destination.exitStop.id = availability.outStop.id!;
      destination.exitStop.name = availability.outStop.name!;
    });
    this.newBooking.destinationId = availability.outStop.id!;
    this.newBooking.serviceAvailabilityResponseId = availability.serviceAvailabilityResponseId;
    this.newBooking.serviceId = availability.serviceId;
  
    this.showExceedingKmLimitModalEventEmitter.emit({newBooking: this.newBooking, maxKmsPerMonth: availability.serviceInfo.managerClient.maxKmsPerMonth});
  };
  
  showBlockedUserModal = (penalty: any) => { 
    this.showBlockedUserModalEventEmitter.emit({penalty: penalty, booking: this.newBooking});
  };

  getDate = () => {
    return this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]] && this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].success[0] ?
      moment(this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].success[0].dateTime).format('DD/MM/YYYY')
      :
      this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]] && moment(this.availabilitiesSeparateByDate[this.availabilitiesDates[this.currentPage]].failure[0].dateTime).format('DD/MM/YYYY');
  };

  back = async () => {
    this.steps.pop();
    this.currentStep = this.steps.at(-1)!;
    this.isBack = true;
    if (this.currentStep === 1) {
      this.newBooking = {} as IBookingCreate;
      this.newBooking.destinationStops = [];
      this.newBooking.stops = [];
      this.newBooking.date = moment().format('YYYY-MM-DD');
      this.newBooking.time = moment().format('HH:mm');
    } else {
      this.creatingBooking = false;
    }
    await this.updateBookingForm(new UserBookingData({showMoreUsers: false}));
    // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, new UserBookingData({showMoreUsers: false}), this.serviceInfos, undefined, ['']);
    this.ngOnInit();
  };

  changeStops = async() => {
    const newBookingCopy = {...this.newBooking};
    if (!this.newBooking.serviceInfo.singleStopReservations) {
      this.newBooking.originStopId = this.newBooking.destinationStops[0].exitStop.id!;
      this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'origin' });
      this.destinationsStops = await this.townsService.getStopsFrom(this.newBooking.townId, this.newBooking.originStopId);
      this.newBooking.destinationStops = [];
      await this.updateBookingForm(undefined, ['stop']);
      // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, undefined, this.destinationsStops, ['stop']);
      newBookingCopy.destinationStops.forEach((destinationStop: any) => {
        destinationStop.exitStop.id = newBookingCopy.originStopId;
      });
      this.newBooking.destinationStops = newBookingCopy.destinationStops;
      await this.updateBookingForm();
      // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, undefined, this.destinationsStops);
      this.selectODEventEmitter.emit({ newBooking: this.newBooking, type: 'destination' });
      this.showMessageIfAnyStopAreIncompatible();
    } else {
      const singleStopReservationType = this.newBooking.destinationStops[0].exitStop.id !== 0 ? SingleStopReservationType.DESTINATION: SingleStopReservationType.ORIGIN;
      if (singleStopReservationType === SingleStopReservationType.DESTINATION) {
        this.newBooking.originStopId = newBookingCopy.destinationStops[0].exitStop.id!;
        this.newBooking.destinationStops.map((destinationStop: any) => {
          destinationStop.exitStop.id = 0;
          destinationStop.exitStop.name = '';
        });
        await this.updateBookingForm();
        // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, this.serviceInfos, this.destinationsStops);
      } else {
        this.newBooking.originStopId = 0;
        this.newBooking.destinationStops.map((destinationStop: any) => {
          destinationStop.exitStop.id = newBookingCopy.originStopId;
        });
        await this.updateBookingForm();
        // this.bookingForm = await newBookingForm(this.newBooking, this.usersService, undefined, this.serviceInfos, this.destinationsStops);
      }
    }
  };

  showMessageIfAnyStopAreIncompatible = () => {
    const { stops, originStopId, destinationStops } = this.newBooking;
    const stopOrigin = stops.find((stop: any) => stop.id === originStopId);
    const stopDestination = this.destinationsStops.find(
      (destinationStop: any) => destinationStop.id === destinationStops[0].exitStop.id);
    if (!stopOrigin && !stopDestination) {
      this.stopOriginNotification = true;
      this.possibleSwap = false;
      setTimeout(() => {
        this.stopOriginNotification = false;
      }, 5000);
    } else if (!stopOrigin) {
      this.stopOriginNotification = true;
      this.possibleSwap = false;
      setTimeout(() => {
        this.stopOriginNotification = false;
      }, 5000);
    } else if (!stopDestination) {
      this.stopDestinationNotification = true;
      this.possibleSwap = false;
      setTimeout(() => {
        this.stopDestinationNotification = false;
      }, 5000);
    }
  };

  disabledViewAvailabilities = () => {
    return this.isSingleReservation ? !this.newBooking.originStopId && this.newBooking.destinationStops.length === 0 : !this.newBooking.originStopId || this.newBooking.destinationStops.length === 0;
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
    animationItem.setSpeed(1.5);
  }

}

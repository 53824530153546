<div [id]="id" tabindex="-1" class="modal absolute top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-96 max-h-full rounded-lg">
        <div class="relative primary-purple-light-2-background rounded-lg shadow dark:bg-gray-700 p-6">
            <form #form novalidate (ngSubmit)="onSubmit({reason, date})">
                <div class="flex">
                    <h3 class="w-full text-xl font-bold primary-purple-text dark:text-gray-400">{{ literalService.get('users.actions.Blocked.title', true) }}</h3>
                    <button (click)="modal.hide()" type="button" class="absolute right-6 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <img [src]="images.close" class="h-6 w-6" />
                    </button>
                </div>
                <div class="pt-6 pb-4 mb-2">
                    <div class="flex">
                        <img [src]="images.comment" alt="comment" class="h-6 w-6 mb-2 mr-2" />
                        <label class="font-normal text-base">{{ literalService.get('users.actions.Blocked.reasonTitle', true) }}</label>
                    </div>
                    <textarea (change)="changeReason(reason)" (input)="onChange()" [(ngModel)]="reason" [ngModelOptions]="{standalone: true}" [placeholder]="literalService.get('users.actions.Blocked.reasonPlaceholder', true)" class="block w-full h-32 px-3 py-2 bg-white border rounded-s rounded-e text-base font-normal shadow-sm placeholder-slate-400 focus:outline-none"></textarea>
                    <label class="text-xs hasErrors" *ngIf="hasError">{{ error }}</label>
                    <label class="text-xs absolute right-6 mt-[2px]" [ngClass]="{'hasErrors': chars > maxLength}">{{ chars + '/' + maxLength}}</label>
                </div>
                <div class="flex mb-2">
                    <img [src]="images.booking.date" alt="date" class="h-6 w-6 mr-2" />
                    <label class="font-normal text-base">{{ literalService.get("bookings.data.date", true) }}</label>
                </div>
                <app-calendar [title]="literalService.get('bookings.data.date', true)" [value]="date" [showOnTop]="true" [onlyFutureDays]="true" [multipleDates]="false" [hideCalendar]="false" (change)="changeDate($event)" />
                <div class="flex justify-center items-center mt-8">
                    <button type="button" class="secondary mt-2 mr-5 gap-4 p-2 border-2 font-semibold w-full uppercase cursor-pointer" (click)="modal.hide()">{{ literalService.get('cancel', true) }}</button>
                    <button type="submit" class="primary mt-2 gap-4 p-2 border-2 font-semibold w-full uppercase cursor-pointer text-white">{{ literalService.get('confirm', true) }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
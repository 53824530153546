import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Modal } from 'flowbite';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { IServiceAvailability, IStopWithDate } from 'src/app/models';
import { IBookingDetails } from 'src/app/models/bookings/bookingDetails';
import { ServicesService } from 'src/app/services';
import { LiteralService } from 'src/app/services/literal/literal.service';
import { images } from 'src/images';

@Component({
  selector: 'app-modal-change-booking-origin-destination',
  templateUrl: './modal-change-booking-origin-destination.component.html',
  styleUrl: './modal-change-booking-origin-destination.component.scss',
})
export class ModalChangeBookingOriginDestinationComponent {
  @Input() id: string;
  @Input() modal: Modal;
  @Input() originElement: any;
  @Input() destinationElement: any;
  @Input() booking: IBookingDetails;
  @Input() updating: boolean;
  @Output() action = new EventEmitter<any>();
  public originSelected: any;
  public destinationSelected: any;
  public availabilitySelected: IServiceAvailability;
  public images = images;
  
  public  options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  public optionsBus: AnimationOptions = {
    path: '/assets/animations/bus.json'
  };
  
  constructor(public literalService: LiteralService, public serviceService: ServicesService) {}

  setOrigin = async (event: any) => {
    const stops: any[] = [];
    await this.serviceService.getDestinationAvailabilityStops(this.booking.availability.id, event.id, 0, 50).then((res) => {
      res.content.map((stop: IStopWithDate) =>{
        const date = new Date(stop.dateTime);
        stops.push(
          {
            id: stop.id,
            value: stop,
            label: `${date.toLocaleTimeString('en-GB', this.options)} - ${stop.name}`,
          }
        );
      });
    });
    stops.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      } else if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    this.destinationElement.edit.values = stops;
    this.originSelected = event;
  };

  setDestination = async (event: any) => {
    this.destinationSelected = event;
  };

  onSubmit = () => {
    this.booking.destination = {
      serviceStopId: this.destinationSelected.id, 
      time: new Date(this.destinationSelected.dateTime).toLocaleTimeString('en-GB', this.options), 
      name: this.destinationSelected.name };
    this.booking.origin = {
      serviceStopId: this.originSelected.id, 
      time: new Date(this.originSelected.dateTime).toLocaleTimeString('en-GB', this.options), 
      name: this.originSelected.name };
    this.action.emit(this.booking);
    this.modal.toggle();
    this.destinationElement.value = "";
    this.originElement.value = "";
    this.modal.toggle();
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
    animationItem.setSpeed(1.5);
  }
}
